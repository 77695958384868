<template>
  <form @submit.prevent="checkCreate()">
    <hr class="line__hr" />

    <h5 class="mb-10">
      Приложение №1 ЗАЯВЛЕНИЕ НА ВЫДАЧУ РЕГИСТРАЦИОННОГО СВИДЕТЕЛЬСТВА ОТ АГЕНТА
    </h5>

    <div
      class="agreement__line"
      v-if="!$route.params.attachment_id && doubleCreate != 'double'"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Подписант контрагента действует на основании
              </div>
              <div class="agreement__item--title" v-else>
                Подписант контрагента действует на основании (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-if="!lang_type"
                  v-model="sobs.client_based_on"
                  placeholder="Подписант контрагента действует на основании"
                  value=""
                />
                <input
                  type="text"
                  required
                  v-else
                  v-model="sobs.client_based_on_kz"
                  placeholder="Подписант контрагента действует на основании (на казахском)"
                  value=""
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="$i18n.locale == 'kz' && messages && messages.client_based_on_kz"
              >
                {{ $t(messages.client_based_on_kz) }}
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="$i18n.locale == 'ru' && messages && messages.client_based_on"
              >
                {{ $t(messages.client_based_on) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="agreement__line"
      v-if="!$route.params.attachment_id && doubleCreate != 'double'"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Название организации
              </div>
              <div class="agreement__item--title" v-else>
                Название организации (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-if="!lang_type"
                  v-model="sobs.name_organization"
                  name=""
                  placeholder="Название организации"
                  value=""
                />
                <input
                  type="text"
                  required
                  v-else
                  v-model="sobs.name_organization_kz"
                  name=""
                  placeholder="Название организации (на казахском)"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="line__hr" />
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Номер регистрационного свидетельства
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Номер регистрационного свидетельства"
                  value=""
                  v-model="formData.certificateNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Бизнес-идентификационный номер</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Бизнес-идентификационный номер"
                  value=""
                  v-model="formData.BusinessIdentificationNumber"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages[
                        'attachments.attachment_1.BusinessIdentificationNumber'
                      ] !== 'undefined',
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Для нерезидентов – регистрационный номер плательщика НДС с указанием страны регистрации
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  placeholder="Номер плательщика НДС с указанием страны регистрации"
                  value=""
                  v-model="formData.VatPayerNumber"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.VatPayerNumber'] !==
                        'undefined',
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование организации
              </div>
              <div class="agreement__item--title" v-else>
                Наименование организации (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input" v-if="!lang_type">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Наименование организации"
                  value=""
                  v-model="formData.NameOfCompany"
                />
              </div>
              <div class="agreement__item--input" v-else>
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Наименование организации"
                  value=""
                  v-model="formData.NameOfCompanyKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Индивидуальный идентификационный номер
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Индивидуальный идентификационный номер"
                  value=""
                  v-model="formData.identityNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-6">
              <div class="agreement__item--title">
                Идентификационные данные сотрудника организации, на имя которого выдается
                регистрационное свидетельство:
              </div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="   Идентификационные данные сотрудника организации, на имя
                    которого выдается регистрационное свидетельство:"
                  value=""
                  v-model="formData.IdentityData"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row align-items-center">
          <div class="col-lg-3"></div>
          <div class="col-lg-9">
            <v-switch
              v-model="resident"
              inset
              :label="resident ? 'Нерезидент' : 'Заполнить поля для нерезидент'"
            ></v-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line" v-if="resident">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Для нерезидентов – номер документа (удостоверяющего личность)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder=" Для нерезидентов – номер документа (удостоверяющего личность)"
                  value=""
                  v-model="formData.documentNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line" v-if="resident">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Для нерезидентов – дата его выдачи (удостоверяющего личность)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input type="date" name="" value="" v-model="formData.documentWhen" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line" v-if="resident">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Для нерезидентов – наименование выдавшего органа с указанием государства выдачи (удостоверяющего личность)
              </div>
              <div class="agreement__item--title" v-else>
                Для нерезидентов – наименование выдавшего органа с указанием государства выдачи (удостоверяющего личность) (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  v-if="!lang_type"
                  type="text"
                  name=""
                  placeholder="Для нерезидентов –  наименование выдавшего органа с указанием государства выдачи (удостоверяющего личность)"
                  value=""
                  v-model="formData.documentGivenBy"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Для нерезидентов –  наименование выдавшего органа с указанием государства выдачи (удостоверяющего личность)"
                  value=""
                  v-model="formData.documentGivenByKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line" v-if="resident">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Для нерезидентов – уникальный номер (удостоверяющего личность)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Для нерезидентов –  наименование выдавшего органа с указанием государства выдачи (удостоверяющего личность)"
                  value=""
                  v-model="formData.documentUnique"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Фамилия</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Фамилия"
                  value=""
                  v-model="formData.surname"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Имя</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Имя"
                  value=""
                  v-model="formData.name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Отчество</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
  
                  name=""
                  placeholder="Отчество"
                  value=""
                  v-model="formData.secondName"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование области
              </div>
              <div class="agreement__item--title" v-else>
                Наименование области (на казахском)
              </div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  v-if="!lang_type"
                  type="text"
                  name=""
                  placeholder="Наименование области"
                  value=""
                  v-model="formData.RegionName"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Наименование области (на казахском)"
                  value=""
                  v-model="formData.RegionNameKz"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title" v-if="!lang_type">Город</div>
              <div class="agreement__item--title" v-else>Город (на казахском)</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  v-if="!lang_type"
                  type="text"
                  name=""
                  placeholder="Город"
                  value=""
                  v-model="formData.city"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Город (на казахском)"
                  value=""
                  v-model="formData.cityKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Адрес электронной почты</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="email"
                  name=""
                  placeholder="Адрес электронной почты"
                  value=""
                  v-model="formData.email"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Телефон</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
								v-mask="{mask: '+7 (999) 999 99 99'}"
                  type="text"
                  required
                  name=""
                  placeholder="Телефон"
                  value=""
                  v-model="formData.telephone"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Срок действия регистрационного свидетельства
              </div>
            </div>
            <div class="col-lg-9">
              <h6 style="color: #135334">14 дней</h6>
              <!-- <div class="agreement__item--input">
                <inpu
                  type="date"
                  name=""
                  placeholder="Срок действия регистрационного свидетельства"
                  value=""
                  v-model="formData.validityOfTheRegistration"
                />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Информация о сферах применения и ограничениях применения регистрационного
                свидетельства электронной цифровой подписи
              </div>
              <div class="agreement__item--title" v-else>
                Информация о сферах применения и ограничениях применения регистрационного
                свидетельства электронной цифровой подписи (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  v-if="!lang_type"
                  type="text"
                  name=""
                  placeholder="Информация о сферах применения и ограничениях применения регистрационного свидетельства электронной цифровой подписи"
                  value=""
                  v-model="formData.ApplicationInformation"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Информация о сферах применения и ограничениях применения регистрационного свидетельства электронной цифровой подписи"
                  value=""
                  v-model="formData.ApplicationInformationKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Данные о средствах электронной цифровой подписи, используемых для создания
                соответствующего закрытого ключа электронной цифровой подписи, обозначение
                стандарта алгоритма электронной цифровой подписи и длины открытого ключа
              </div>
              <div class="agreement__item--title" v-else>
                Данные о средствах электронной цифровой подписи, используемых для создания
                соответствующего закрытого ключа электронной цифровой подписи, обозначение
                стандарта алгоритма электронной цифровой подписи и длины открытого ключа
                (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  v-if="!lang_type"
                  type="text"
                  name=""
                  placeholder="Данные о средствах электронной цифровой подписи, используемых для создания соответствующего закрытого ключа электронной цифровой подписи, обозначение стандарта алгоритма электронной цифровой подписи и длины открытого ключа"
                  value=""
                  v-model="formData.dataOnTheMeansOfElectronic"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Данные о средствах электронной цифровой подписи, используемых для создания соответствующего закрытого ключа электронной цифровой подписи, обозначение стандарта алгоритма электронной цифровой подписи и длины открытого ключа"
                  value=""
                  v-model="formData.dataOnTheMeansOfElectronicKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Открытый ключ</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Открытый ключ"
                  value=""
                  v-model="formData.PublicKey"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Действия, которые запрашивается выполнить (необходимое отметить)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--list">
                <ol class="form__login--check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        name="1"
                        v-model="formData.ActionsRequestedToPerform"
                        disabled
                      />

                      <div class="form__checkbox--text">
                        Изготовить криптографические ключи следующего назначения
                      </div>
                    </label>
                    <ul>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            class="mr-2"
                            name="1-1"
                            v-model="formData.GenerateCryptographicLeys"
                            @input="clickFirstAreaCheckbox(1)"
                          />

                          <div
                            class="form__checkbox--text"
                            v-bind:class="{
                              border__red: checkFirstAreaClicked() == false,
                            }"
                          >
                            Первичная инициализация
                          </div>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            class="mr-2"
                            name="1-1"
                            v-model="formData.InitialInitialization"
                            @input="clickFirstAreaCheckbox(2)"
                          />

                          <div
                            class="form__checkbox--text"
                            v-bind:class="{
                              border__red: checkFirstAreaClicked() == false,
                            }"
                          >
                            Электронная цифровая подпись
                          </div>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            class="mr-2"
                            name="2"
                            v-model="formData.MakeRegister"
                            @input="clickFirstAreaCheckbox(3)"
                          />

                          <div
                            class="form__checkbox--text"
                            v-bind:class="{
                              border__red: checkFirstAreaClicked() == false,
                            }"
                          >
                            Изготовить и зарегистрировать в регистре регистрационных
                            свидетельств Удостоверяющего центра регистрационное
                            свидетельство
                          </div>
                        </label>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        name="3"
                        v-model="formData.AssignInRegistration"
                        disabled
                      />

                      <div class="form__checkbox--text">
                        Назначить в регистрационном свидетельстве свойство
                      </div>
                    </label>
                    <ul>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            class="mr-2"
                            v-model="formData.updateManager"
                            @input="clickSecondAreaCheckbox(1)"
                          />

                          <div
                            class="form__checkbox--text"
                            v-bind:class="{
                              border__red: checkSecondAreaClicked() == false,
                            }"
                          >
                            Менеджера актуализации
                          </div>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            class="mr-2"
                            v-model="formData.initManager"
                            @input="clickSecondAreaCheckbox(2)"
                          />

                          <div
                            class="form__checkbox--text"
                            v-bind:class="{
                              border__red: checkSecondAreaClicked() == false,
                            }"
                          >
                            Менеджера инициализации
                          </div>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            class="mr-2"
                            v-model="formData.manager"
                            @input="clickSecondAreaCheckbox(3)"
                          />

                          <div
                            class="form__checkbox--text"
                            v-bind:class="{
                              border__red: checkSecondAreaClicked() == false,
                            }"
                          >
                            Менеджера
                          </div>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            class="mr-2"
                            v-model="formData.officer"
                            @input="clickSecondAreaCheckbox(4)"
                          />

                          <div
                            class="form__checkbox--text"
                            v-bind:class="{
                              border__red: checkSecondAreaClicked() == false,
                            }"
                          >
                            Офицера
                          </div>
                        </label>
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Настоящее заявление прошу принять от
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input mb-3">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Настоящее заявление прошу принять от"
                  value=""
                  v-model="formData.pleaseAcceptApplication"
                />
              </div>
              <div class="agreement__item--input mb-3">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Номер удостоверения личности/паспорта доверенного лица"
                  value=""
                  v-model="formData.pleaseAcceptApplicationNumber"
                />
              </div>
              <div class="agreement__item--input mb-3">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Кем выдано (удостоверения личности/паспорта доверенного лица)"
                  value=""
                  v-model="formData.pleaseAcceptApplicationWhoGiven"
                />
              </div>
              <div class="agreement__item--input mb-3">
                <input
                  type="date"
                  name=""
                  placeholder="Когда выдано (удостоверения личности/паспорта доверенного лица)"
                  value=""
                  v-model="formData.pleaseAcceptApplicationDate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-select
      :items="users"
      :hide-details="true"
      :flat="true"
      item-text="common_name"
      item-value="id"
      dense
      outlined
      v-model="user"
      class="mb-5"
      label="Выберите доверенного лица Агента"
      @change="selectedUser"
    ></v-select>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Фамилия И.О. доверенного лица Агента
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Фамилия И.О. доверенного лица Агента"
                  value=""
                  v-model="formData.agentsProxy"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Файл</div>
            </div>
            <v-file-input
              v-model="images"
              placeholder=""
              label="Выберите файл"
              multiple
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </div>
        </div>
      </div>
    </div>
    <hr class="line__hr" />

    <div class="row">
      <div class="col-lg-9 offset-lg-3">
        <button type="submit" class="form__button">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
              fill="white"
            ></path>
          </svg>
          Отправить
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from 'vue';

const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)
export default {
  props: ["lang_type", "fields", "doubleCreate"],
  components: {},
  data() {
    return {
      resident: false,
      users: [],
      user: "",
      images: [],
      messages: [],
      sobs: {
        contragent: "",
        contragent_kz: "",
        name_organization: "",
        name_organization_kz: "",
        client_common_name: "",
        client_last_name: "",
        client_signer_in: "",
        client_based_on: "Устава",
        client_phone_number: "",
        client_common_name_kz: "",
        client_last_name_kz: "",
        client_signer_in_kz: "",
        client_based_on_kz: "Жарғы",
      },
      formData: {
        certificateNumber: "",
        BusinessIdentificationNumber: "",
        VatPayerNumber: "",
        NameOfCompany: "",
        NameOfCompanyKz: "",
        IdentityData: "",
        ForNoResidents: "",
        surname: "",
        name: "",
        secondName: "",
        RegionName: "",
        RegionNameKz: "",
        city: "",
        cityKz: "",
        email: "",
        telephone: "",
        validityOfTheRegistration: "",
        ApplicationInformation: "",
        ApplicationInformationKz: "",
        dataOnTheMeansOfElectronic: "",
        dataOnTheMeansOfElectronicKz: "",
        PublicKey: "",
        ActionsRequestedToPerform: true,
        GenerateCryptographicLeys: "",
        InitialInitialization: "",
        MakeRegister: "",
        AssignInRegistration: true,
        updateManager: "",
        initManager: "",
        manager: "",
        officer: "",
        pleaseAcceptApplication: "",
        documentGivenBy: "",
        documentGivenByKz: "",
        documentUnique: "",
        documentWhen: "",
        documentNumber: "",
        pleaseAcceptApplicationNumber: "",
        pleaseAcceptApplicationWhoGiven: "",
        pleaseAcceptApplicationDate: "",
      },
    };
  },
  mounted() {
    this.getProfile();
    this.getUsers();
    if (this.fields != "") {
      this.formData = this.fields.fields;
      this.user = this.fields.responsible_id;
    }
  },
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
  watch: {
    fields(val) {
      if (val) {
        !val.fields.ActionsRequestedToPerform ||
        val.fields.ActionsRequestedToPerform == "null" ||
        val.fields.ActionsRequestedToPerform == "false"
          ? (val.fields.ActionsRequestedToPerform = false)
          : (val.fields.ActionsRequestedToPerform = true);
        !val.fields.GenerateCryptographicLeys ||
        val.fields.GenerateCryptographicLeys == "null" ||
        val.fields.GenerateCryptographicLeys == "false"
          ? (val.fields.GenerateCryptographicLeys = false)
          : (val.fields.GenerateCryptographicLeys = true);
        !val.fields.InitialInitialization ||
        val.fields.InitialInitialization == "null" ||
        val.fields.InitialInitialization == "false"
          ? (val.fields.InitialInitialization = false)
          : (val.fields.InitialInitialization = true);
        !val.fields.MakeRegister ||
        val.fields.MakeRegister == "null" ||
        val.fields.MakeRegister == "false"
          ? (val.fields.MakeRegister = false)
          : (val.fields.MakeRegister = true);
        !val.fields.AssignInRegistration ||
        val.fields.AssignInRegistration == "null" ||
        val.fields.AssignInRegistration == "false"
          ? (val.fields.AssignInRegistration = false)
          : (val.fields.AssignInRegistration = true);
        !val.fields.updateManager ||
        val.fields.updateManager == "null" ||
        val.fields.updateManager == "false"
          ? (val.fields.updateManager = false)
          : (val.fields.updateManager = true);
        !val.fields.initManager ||
        val.fields.initManager == "null" ||
        val.fields.initManager == "false"
          ? (val.fields.initManager = false)
          : (val.fields.initManager = true);
        !val.fields.manager ||
        val.fields.manager == "null" ||
        val.fields.manager == "false"
          ? (val.fields.manager = false)
          : (val.fields.manager = true);
        !val.fields.officer ||
        val.fields.officer == "null" ||
        val.fields.officer == "false"
          ? (val.fields.officer = false)
          : (val.fields.officer = true);

        this.formData = val.fields;
        this.user = this.fields.responsible_id;
      }
    },
  },
  methods: {
    checkFirstAreaClicked() {
      if (
        this.formData.InitialInitialization == false &&
        this.formData.MakeRegister == false &&
        this.formData.GenerateCryptographicLeys == false
      ) {
        return false;
      }
    },
    checkSecondAreaClicked() {
      if (
        this.formData.initManager == false &&
        this.formData.manager == false &&
        this.formData.officer == false &&
        this.formData.updateManager == false
      ) {
        return false;
      }
    },
    clickFirstAreaCheckbox(index) {
      if (index == 1) {
        this.formData.InitialInitialization = false;
        this.formData.MakeRegister = false;
      } else if (index == 2) {
        this.formData.MakeRegister = false;
        this.formData.GenerateCryptographicLeys = false;
      } else if (index == 3) {
        this.formData.GenerateCryptographicLeys = false;
        this.formData.InitialInitialization = false;
      }
    },
    clickSecondAreaCheckbox(index) {
      if (index == 1) {
        this.formData.initManager = false;
        this.formData.manager = false;
        this.formData.officer = false;
      } else if (index == 2) {
        this.formData.updateManager = false;
        this.formData.manager = false;
        this.formData.officer = false;
      } else if (index == 3) {
        this.formData.initManager = false;
        this.formData.updateManager = false;
        this.formData.officer = false;
      } else if (index == 4) {
        this.formData.manager = false;
        this.formData.initManager = false;
        this.formData.updateManager = false;
      }
    },
    checkCreate() {
      if (this.doubleCreate == "double") {
        this.$parent.createContract();
      } else {
        this.createContract();
      }
    },
    selectedUser() {
      let filtered_user = this.users.filter((item) => item.id == this.user)[0];
      this.formData.agentsProxy =
        filtered_user.common_name + " " + filtered_user.last_name;
    },
    getUsers() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "user/organization/users",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    getProfile() {
      this.sobs.name_organization = this.GET_USER_DATA.organization.name;
      this.sobs.name_organization_kz = this.GET_USER_DATA.organization.name;
      this.sobs.contragent = this.GET_USER_DATA.organization.name;
      this.sobs.contragent_kz = this.GET_USER_DATA.organization.name;
      this.sobs.client_fio =
        this.GET_USER_DATA.common_name + " " + this.GET_USER_DATA.last_name;
      this.sobs.client_fio_kz =
        this.GET_USER_DATA.common_name + " " + this.GET_USER_DATA.last_name;
      this.sobs.client_signer_in = this.GET_USER_DATA.role.name_ru;
      this.sobs.client_signer_in_kz = this.GET_USER_DATA.role.name_ru;
      this.sobs.client_last_name = this.GET_USER_DATA.last_name;
      this.sobs.client_last_name_kz = this.GET_USER_DATA.last_name;

      this.formData.NameOfCompany = this.GET_USER_DATA.organization.name;
      this.formData.NameOfCompanyKz = this.GET_USER_DATA.organization.name_kz
        ? this.GET_USER_DATA.organization.name_kz
        : this.GET_USER_DATA.organization.name;
    },
    createContract() {
      if (this.checkFirstAreaClicked() == false) {
        this.$toast.open({
          message: "Выберите один из вариантов",
          type: "warning",
          position: "bottom",
          duration: 4000,
          queue: true,
        });
        return false;
      } else if (this.checkSecondAreaClicked() == false) {
        this.$toast.open({
          message: "Выберите один из вариантов",
          type: "warning",
          position: "bottom",
          duration: 4000,
          queue: true,
        });
        return false;
      }

      let contractForm = new FormData();
      for (var key in this.sobs) {
        contractForm.append(key, this.sobs[key]);
      }
      for (var key1 in this.formData) {
        contractForm.append(
          "attachments[attachment_1][" + key1 + "]",
          this.formData[key1]
        );
      }
      for (var i = 0; i < this.images.length; i++) {
        contractForm.append("documents[]", this.images[i]);
      }
      if (this.$route.params.attachment_id) {
        contractForm.append("attachment_id", this.$route.params.attachment_id);
        contractForm.append("id", this.$route.params.id);
      }
      contractForm.append("type", "attachment_1");
      contractForm.append("responsible_id", this.user);

      this.$axios
        .post(this.$API_URL + this.$API_VERSION + "user/documents/uc", contractForm, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          this.$toast.open({
            message: "Успешно заполнено",
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });

          this.$router.go(-1);
        })
        .catch((error) => {
					if (error.response.data.message) {
						this.$toast.open({
              message: error.response.data.message,
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
					}
          if (error.response && error.response.status == 422) {
            this.$toast.open({
              message: "Заполните все поля на русском и казахском",
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
            this.messages = [];
            for (let key in error.response.data.errors) {
              this.messages[key] = error.response.data.errors[key].pop();
            }
          }
        });
    },
  },
};
</script>
<style lang="scss">
.border__red {
  border: 2px solid #c1790c !important;
  padding: 2px;
}
</style>
