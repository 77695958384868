<template>
    <form  @submit.prevent="$parent.createContract()" class="uc__attachment">
        <hr class="line__hr" />
        <h5 class="mb-10">Приложение №2 СВОЙСТВА РЕГИСТРАЦИОННЫХ СВИДЕТЕЛЬСТВ</h5>

        <div class="agreement__line">
          <div class="row">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-lg-3">


                  <div class="agreement__item--title">
                    Формирование запроса на внесение данных подписывающего лица
                  </div>
                </div>

                <div class="col-lg-9">
                    <div class="item__column">
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.updateManagerFirst"/>
                          <p>Менеджера актуализации</p>     
                        </div>
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.initManagerFirst"/>
                          <p>Менеджера инициализации</p>     
                        </div>
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.managerFirst"/>
                          <p>Менеджера</p>     
                        </div>
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.officerFirst"/>
                          <p>Офицера</p>     
                        </div>
                    </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div class="agreement__line">
          <div class="row">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-lg-3">
                  <div class="agreement__item--title">
                    Формирование запроса на выпуск/отзыв регистрационного
                    свидетельства для подписывающего лица
                  </div>
                </div>
                <div class="col-lg-9">
                    <div class="item__column">
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.updateManagerSecond"/>
                          <p>Менеджера актуализации</p>     
                        </div>
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.initManagerSecond"/>
                          <p>Менеджера инициализации</p>     
                        </div>
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.managerSecond"/>
                          <p>Менеджера</p>     
                        </div>
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.officerSecond"/>
                          <p>Офицера</p>     
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="agreement__line">
          <div class="row">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-lg-3">
                  <div class="agreement__item--title">
                    Формирование запросов на внесение данных любого типа
                    менеджера/выпуск и отзыв регистрационного свидетельства для
                    любого типа менеджера
                  </div>
                </div>
                <div class="col-lg-9">
                    <div class="item__column">
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.updateManagerThird"/>
                          <p>Менеджера актуализации</p>     
                        </div>
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.initManagerThird"/>
                          <p>Менеджера инициализации</p>     
                        </div>
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.managerThird"/>
                          <p>Менеджера</p>     
                        </div>
                        <div class="item__row item__ac">
                          <input type="checkbox" class="mr-2" v-model="formData.officerThird"/>
                          <p>Офицера</p>     
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="line__hr" />

        <div class="row">
          <div class="col-lg-9 offset-lg-3">
            <button type="submit" class="form__button">
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
                  fill="white"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
                  fill="white"
                ></path>
              </svg>
              Отправить
            </button>
          </div>
        </div>
      </form>
</template>
<script>
export default {
  props: ['fields'],
  components: {},
  data() {
    return {
      formData: {
        updateManagerThird: false,
        initManagerThird: false,
        managerThird: false,
        officerThird: false,
         updateManagerSecond: false,
        initManagerSecond: false,
        managerSecond: false,
        officerSecond: false,
        updateManagerFirst: false,
        initManagerFirst: false,
        managerFirst: false,
        officerFirst: false
      },
    };
  },
  methods: {
   
  },
  mounted() {
    if(this.fields!='') {

      this.formData = this.fields.fields;
    }
  },
  watch: {
    fields(val) {
      if(val.fields) {
        !val.fields.updateManagerThird  || val.fields.updateManagerThird=="null"||val.fields.updateManagerThird=="false"?val.fields.updateManagerThird=false:val.fields.updateManagerThird=true;
        !val.fields.initManagerThird || val.fields.initManagerThird=="null"||val.fields.initManagerThird=="false"?val.fields.initManagerThird=false:val.fields.initManagerThird=true;
        !val.fields.managerThird || val.fields.managerThird=="null"||val.fields.managerThird=="false"?val.fields.managerThird=false:val.fields.managerThird=true;
        !val.fields.officerThird || val.fields.officerThird=="null"||val.fields.officerThird=="false"?val.fields.officerThird=false:val.fields.officerThird=true;

        !val.fields.officerFirst || val.fields.officerFirst=="null"||val.fields.officerFirst=="false"?val.fields.officerFirst=false:val.fields.officerFirst=true;
        !val.fields.managerFirst || val.fields.managerFirst=="null"||val.fields.managerFirst=="false"?val.fields.managerFirst=false:val.fields.managerFirst=true;
        !val.fields.updateManagerFirst || val.fields.updateManagerFirst=="null"||val.fields.updateManagerFirst=="false"?val.fields.updateManagerFirst=false:val.fields.updateManagerFirst=true;
        !val.fields.initManagerFirst || val.fields.initManagerFirst=="null"||val.fields.initManagerFirst=="false"?val.fields.initManagerFirst=false:val.fields.initManagerFirst=true;
        
        !val.fields.officerSecond || val.fields.officerSecond=="null"||val.fields.officerSecond=="false"?val.fields.officerSecond=false:val.fields.officerSecond=true;
        !val.fields.managerSecond || val.fields.managerSecond=="null"||val.fields.managerSecond=="false"?val.fields.managerSecond=false:val.fields.managerSecond=true;
        !val.fields.updateManagerSecond || val.fields.updateManagerSecond=="null"||val.fields.updateManagerSecond=="false"?val.fields.updateManagerSecond=false:val.fields.updateManagerSecond=true;
        !val.fields.initManagerSecond || val.fields.initManagerSecond=="null"||val.fields.initManagerSecond=="false"?val.fields.initManagerSecond=false:val.fields.initManagerSecond=true;
        this.formData = this.fields.fields;
      }
    }
  }
};
</script>
<style lang="scss">

.uc__attachment {
  p {
    margin-bottom: 0;
  }
}
</style>
