<template>
  <div class="wrapper">
    <div class="container">
      <div class="section__line pointer">
        <div class="link__top">
          <a class="link__back pointer" @click="$router.go(-1)">
            <span class="link__back--img">
              <img src="../../../assets/img/arrow-back.svg" />
            </span>
            Назад
          </a>
        </div>
      </div>

      <div class="section__line">
        <div class="section__bg section--bank__info section--bank__data">
          <div class="section__title--block section__title--margin">
            <div class="item__column">
              <div class="section__title" v-if="!$route.params.attachment_id">
                Создание приложение
              </div>
              <div class="section__title" v-else>Редактирование приложение</div>

              <div class="item__row item__ac agreement__lang mt-4">
                <div
                  class="agreement__lang__type item__abs mr-2 pointer"
                  @click="langRu = true"
                  v-bind:class="{ agreement__lang__active: langRu == true }"
                >
                  RU
                </div>
                <div
                  class="agreement__lang__type item__abs pointer"
                  @click="langRu = false"
                  v-bind:class="{ agreement__lang__active: langRu == false }"
                >
                  KZ
                </div>
              </div>
            </div>

            <div class="type__contract">
              <div class="type__contract--img mr-3">
                <img src="../../../assets/img/type__contract.svg" />
              </div>
              <div class="type__contract--info">
                <div class="type__contract--type">Тип договора</div>
                <div class="type__contract--name">
                  Договор о предоставлении услуг Удостоверяющего центра
                </div>
              </div>
            </div>
          </div>

          <div class="agreement__info--block">
            <div class="agreement__info--item">
              <div class="agreement__info--icon">
                <img src="../../../assets/img/file-icon.svg" />
              </div>
              <div class="agreement__info--right">
                <div class="agreement__info--type">Вид договора</div>
                <div class="agreement__info--title">Внешний</div>
              </div>
            </div>
          </div>

          <Contract111
            :fields="fields"
            :doubleCreate="'double'"
            :lang_type="langRu"
            ref="ucZeroAttachment"
            v-if="$route.query && $route.query.type == 1"
          />
          <Contract122
            :fields="fields"
            :lang_type="langRu"
            ref="ucFirstAttachment"
            v-if="$route.query && $route.query.type == 2"
          />
          <Contract123
            :fields="fields"
            :lang_type="langRu"
            ref="ucSecondAttachment"
            v-if="$route.query && $route.query.type == 3"
          />
          <Contract124
            :fields="fields"
            :lang_type="langRu"
            ref="ucThirdAttachment"
            v-if="$route.query.type == 4"
          />
          <UcContract
            :fields="fields"
            :lang_type="langRu"
            ref="ucContract"
            v-if="$route.query && $route.query.type == 'contract'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UcContract from "./UcContract";
import Contract111 from "./attachment-1";
import Contract122 from "./contract12-2";
import Contract123 from "./contract12-3";
import Contract124 from "./contract12-4";

export default {
  components: {
    UcContract,
    Contract111,
    Contract122,
    Contract123,
    Contract124,
  },
  data() {
    return {
      langRu: true,
      contract_title: {
        contract_full_name: "",
        contract_full_name_kz: "",
      },
      document: {
        id: "",
        file_path: "",
        types: "",
        type: "Выберите тип договора",
      },
      contract: {
        type: "",
      },
      images: [],
      messages: null,
      attachments: [],
      fields: "",
    };
  },
  methods: {
    getTypes() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "types",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document.types = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    createContract() {
      let contractForm = new FormData();

      if (this.$route.query.type == 1)
        this.attachments = this.$refs.ucZeroAttachment.formData;
      // if (this.$refs.ucZeroAttachment.checkFirstAreaClicked() == false) {
      //   this.$toast.open({
      //     message:
      //       "Выберите один из вариантов пункте: Изготовить криптографические ключи следующего назначения",
      //     type: "warning",
      //     position: "bottom",
      //     duration: 4000,
      //     queue: true,
      //   });
      //   return false;
      // } else if (this.$refs.ucZeroAttachment.checkSecondAreaClicked() == false) {
      //   this.$toast.open({
      //     message:
      //       "Выберите один из вариантов пункте: Назначить в регистрационном свидетельстве свойство",
      //     type: "warning",
      //     position: "bottom",
      //     duration: 4000,
      //     queue: true,
      //   });
      //   return false;
      // }
      if (this.$route.query.type == 2)
        this.attachments = this.$refs.ucFirstAttachment.formData;
      if (this.$route.query.type == 3)
        this.attachments = this.$refs.ucSecondAttachment.formData;
      if (this.$route.query.type == 4)
        this.attachments = this.$refs.ucThirdAttachment.formData;

      for (var key in this.attachments) {
        contractForm.append(
          "attachments[attachment_" + this.$route.query.type + "][" + key + "]",
          this.attachments[key]
        );
      }
      contractForm.append("id", this.$route.params.id);
      contractForm.append("type", "attachment_" + this.$route.query.type);

      if (this.$route.params.attachment_id) {
        contractForm.append("attachment_id", this.$route.params.attachment_id);
      }
      let type = "";
      this.GET_USER_DATA.admin == false ? (type = "user") : (type = "admin");

      this.$axios
        .post(
          this.$API_URL + this.$API_VERSION + type + "/documents/uc/create/attachment",
          contractForm,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.$toast.open({
              message: "Успешно заполнено",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
          this.$router.go(-1);
        })
        .catch((error) => {
          if (error.response && error.response.status == 422) {
            this.$toast.open({
              message: "Заполните все поля",
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
            // this.messages = [];
            // for (let key in error.response.data.errors) {
            //     this.messages[key] = error.response.data.errors[key].pop();
            //     console.log(key);
            // }
          }
        });
    },
    getAttachment() {
      let type = "";
      this.GET_USER_DATA.admin == false ? (type = "user") : (type = "admin");

      this.$axios
        .get(
          this.$API_URL +
            this.$API_VERSION +
            type +
            "/attachment?id=" +
            this.$route.params.attachment_id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.fields = response.data;
        })
        .catch((error) => {
          console.log(error.response);

          let errors = error.response.data.errors;
          console.log(errors);

          this.$toast.open({
            message: "Заполните все поля",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        });
    },
    getDocument(id) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "user/document?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.fields = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  mounted() {
    this.getTypes();
    if (this.$route.params.attachment_id) {
      this.getAttachment();
    }
    if (this.$route.query.type == "contract") {
      this.getDocument(this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>
