<template>
  <form @submit.prevent="$parent.createContract()">
    <hr class="line__hr" />

    <h5 class="mb-10">
      Приложение №4 ЗАЯВЛЕНИЕ НА ОТЗЫВ РЕГИСТРАЦИОННОГО СВИДЕТЕЛЬСТВА ОТ АГЕНТА
    </h5>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Бизнес-идентификационный номер</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder=" "
                  value=""
                  v-model="formData.BusinessIdentificationNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Для нерезидентов – регистрационный номер плательщика НДС с указанием страны регистрации
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Для нерезидентов – регистрационный номер плательщика НДС с указанием страны регистрации"
                  value=""
                  v-model="formData.ForNoResidents"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="lang_type">
                Наименование организации
              </div>
              <div class="agreement__item--title" v-else>
                Наименование организации (на казахском)
              </div>
            </div>

            <div class="col-lg-9">
              <div class="agreement__item--input" v-if="lang_type">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Наименование организации"
                  value=""
                  v-model="formData.NameOfCompany"
                />
              </div>
              <div class="agreement__item--input" v-else>
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Наименование организации"
                  value=""
                  v-model="formData.NameOfCompanyKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">
                Идентификационные данные сотрудника организации, на имя которого выдано
                отзываемое регистрационное свидетельство
              </div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Идентификационные данные сотрудника организации, на имя которого выдано отзываемое регистрационное свидетельство"
                  value=""
                  v-model="formData.EmployeeIdentity"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">
                Индивидуальный идентификационный номер
              </div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Индивидуальный идентификационный номер"
                  value=""
                  v-model="formData.IndividualIdentificationNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Для нерезидентов – номер документа (удостоверяющего личность)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder=" Для нерезидентов – номер документа (удостоверяющего личность)"
                  value=""
                  v-model="formData.documentNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Для нерезидентов – дата его выдачи (удостоверяющего личность)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="date"
                  name=""
                  placeholder="Для нерезидентов – дата его выдачи (удостоверяющего личность)"
                  value=""
                  v-model="formData.documentDate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Для нерезидентов – наименование выдавшего органа с указанием государства выдачи (удостоверяющего личность)
              </div>
              <div class="agreement__item--title" v-else>
                Для нерезидентов – наименование выдавшего органа с указанием государства выдачи (удостоверяющего личность) (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  v-if="!lang_type"
                  type="text"
                  name=""
                  placeholder="Для нерезидентов –  наименование выдавшего органа с указанием государства выдачи (удостоверяющего личность)"
                  value=""
                  v-model="formData.documentGivenBy"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Для нерезидентов –  наименование выдавшего органа с указанием государства выдачи (удостоверяющего личность)"
                  value=""
                  v-model="formData.documentGivenByKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Для нерезидентов – уникальный номер (удостоверяющего личность)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Для нерезидентов –  уникальный номер (удостоверяющего личность)"
                  value=""
                  v-model="formData.documentUnique"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Фамилия</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Фамилия"
                  value=""
                  v-model="formData.Surname"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Имя</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Имя"
                  value=""
                  v-model="formData.name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Отчество</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Отчество"
                  value=""
                  v-model="formData.patronymic"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Адрес электронной почты</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="email"
                  name=""
                  placeholder="Адрес электронной почты"
                  value=""
                  v-model="formData.email"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Телефон</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
								v-mask="{mask: '+7 (999) 999 99 99'}"
                  type="text"
                  name=""
                  placeholder="Телефон"
                  value=""
                  v-model="formData.telephone"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="line__hr" />

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Настоящим (наименование Агента)
              </div>
              <div class="agreement__item--title" v-else>
                Настоящим (наименование Агента) (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  v-if="!lang_type"
                  type="text"
                  name=""
                  placeholder="наименование Агента"
                  value=""
                  v-model="formData.AgentName"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="наименование Агента  (на казахском)"
                  value=""
                  v-model="formData.AgentName"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="lang_type">Юридический адрес</div>
              <div class="agreement__item--title" v-else>
                Юридический адрес (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Юридический адрес"
                  value=""
                  v-model="formData.LegalAddress"
                  v-if="lang_type"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Юридический адрес (на казахском)"
                  value=""
                  v-model="formData.LegalAddressKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Свидетельство о регистрации №</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Свидетельство о регистрации №"
                  value=""
                  v-model="formData.RegistrationCertificate"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">от</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="date"
                  name=""
                  placeholder="от"
                  value=""
                  v-model="formData.from"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Выдано (когда)</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input type="date" name="" value="" v-model="formData.when" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="lang_type">Выдано (кем)</div>
              <div class="agreement__item--title" v-else>Выдано (кем) (на казахском)</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  v-if="lang_type"
                  type="text"
                  name=""
                  placeholder="Выдано (кем)"
                  value=""
                  v-model="formData.byGiven"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Выдано (кем) (на казахском)"
                  value=""
                  v-model="formData.byGivenKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">В лице ( фамилия, инициалы)</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="фамилия, инициалы"
                  value=""
                  v-model="formData.positionSurnameInitials"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="lang_type">Должность</div>
              <div class="agreement__item--title" v-else>Должность (на казахском)</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  v-if="lang_type"
                  type="text"
                  name=""
                  placeholder="Должность"
                  value=""
                  v-model="formData.SignIn"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Должность (на казахском)"
                  value=""
                  v-model="formData.SignInKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="lang_type">
                Действующего на основании
              </div>
              <div class="agreement__item--title" v-else>
                Действующего на основании (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  v-if="lang_type"
                  type="text"
                  name=""
                  placeholder="Действующего на основании"
                  value=""
                  v-model="formData.ActingBasis"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Действующего на основании (на казахском)"
                  value=""
                  v-model="formData.ActingBasisKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="lang_type">
                В связи с (причина отзыва регистрационного свидетельства: компрометация
                закрытого ключа, изменение информации, замена ключа)
              </div>
              <div class="agreement__item--title" v-else>
                В связи с (причина отзыва регистрационного свидетельства: компрометация
                закрытого ключа, изменение информации, замена ключа) (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  v-if="lang_type"
                  type="text"
                  name=""
                  placeholder="Причина отзыва регистрационного свидетельства"
                  value=""
                  v-model="formData.ReasonRevocation"
                />
                <input
                  v-else
                  type="text"
                  name=""
                  placeholder="Причина отзыва регистрационного свидетельства"
                  value=""
                  v-model="formData.ReasonRevocationKz"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Просит отозвать регистрационное свидетельство с серийным номером
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  name=""
                  placeholder="Просит отозвать регистрационное свидетельство с серийным номером"
                  value=""
                  v-model="formData.AsksWithdraw"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="line__hr" />

    <div class="row">
      <div class="col-lg-9 offset-lg-3">
        <button type="submit" class="form__button">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
              fill="white"
            ></path>
          </svg>
          Отправить
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import { mapGetters } from "vuex";
import Vue from 'vue';

const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)
export default {
  props: ["fields", "lang_type"],
  components: {},
  data() {
    return {
      formData: {
        BusinessIdentificationNumber: "",
        ForNoResidents: "",
        NameOfCompany: "",
        NameOfCompanyKz: "",
        EmployeeIdentity: "",
        IndividualIdentificationNumber: "",
        documentNumber: "",
        documentDate: "",
        documentGivenBy: "",
        documentGivenByKz: "",
        documentUnique: "",
        Surname: "",
        name: "",
        patronymic: "",
        email: "",
        when: "",
        byGiven: "",
        byGivenKz: "",
        telephone: "",
        AgentName: "",
        LegalAddress: "",
        LegalAddressKz: "",
        RegistrationCertificate: "",
        from: "",
        positionSurnameInitials: "",
        ActingBasis: "",
        ActingBasisKz: "",
        ReasonRevocation: "",
        ReasonRevocationKz: "",
        AsksWithdraw: "",
        SignIn: "",
        SignInKz: "",
      },
    };
  },
  methods: {
    getProfile() {
      this.formData.NameOfCompany = this.GET_USER_DATA.organization.name;
      this.formData.NameOfCompanyKz = this.GET_USER_DATA.organization.name_kz
        ? this.GET_USER_DATA.organization.name_kz
        : this.GET_USER_DATA.organization.name;
      this.formData.LegalAddressKz = this.GET_USER_DATA.organization.address_kz
        ? this.GET_USER_DATA.organization.address_kz
        : this.GET_USER_DATA.organization.address;
      this.formData.LegalAddress = this.GET_USER_DATA.organization.address;
    },
  },
  mounted() {
    if (this.fields != "") {
      this.formData = this.fields.fields;
    }
    this.getProfile();
  },
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
  watch: {
    fields(val) {
      if (val) {
        this.formData = this.fields.fields;
      }
    },
  },
};
</script>
<style lang="scss"></style>
